var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-container",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          staticClass: "ma-0 pa-0",
          attrs: { fluid: "" },
        },
        [
          _c("v-data-table", {
            class: _vm.table,
            attrs: {
              headers: _vm.headers,
              items: _vm.layouts,
              height: _vm.tableHeight,
              "no-data-text": "No Layouts to Display",
              loading: _vm.loading,
              search: _vm.search,
              "fixed-header": "",
              dense: "",
            },
            on: {
              "update:items": function ($event) {
                _vm.layouts = $event
              },
              "click:row": _vm.editLayout,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "fill", flat: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "", align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "4", lg: "2", xl: "2" } },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "accent--text font-weight-normal",
                                    },
                                    [_vm._v("Layout Manager")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: `item.audit.created_on`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.audit.created_on
                              ? _vm.formatDateDisplay(item.audit.created_on)
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: `item.audit.updated_on`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.audit.updated_on
                              ? _vm.formatDateDisplay(item.audit.updated_on)
                              : ""
                          ) +
                          " "
                      ),
                    ]
                  },
                },
                {
                  key: `item.features`,
                  fn: function ({ item }) {
                    return [
                      _vm._v(" " + _vm._s(_vm.getFeatureLength(item)) + " "),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c(_vm.component, {
            key: _vm.componentKey,
            tag: "Component",
            attrs: {
              value: _vm.modal,
              selectedLayout: _vm.selectedLayout,
              defaultParty: _vm.defaultParty,
            },
            on: {
              "update:value": function ($event) {
                _vm.modal = $event
              },
              refresh: _vm.getLayouts,
            },
          }),
        ],
        1
      ),
      _c(
        "v-fab-transition",
        [
          _c(
            "v-tooltip",
            {
              attrs: { left: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "primary",
                            style: _vm.floatingButton,
                            attrs: {
                              absolute: "",
                              bottom: "",
                              right: "",
                              fab: "",
                            },
                            on: {
                              click: function ($event) {
                                ;(_vm.component = "LayoutBuilder"),
                                  (_vm.modal = true)
                              },
                            },
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v("Create Layout")])]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }